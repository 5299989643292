// INFO: 연구실 상세 정보 보기 화면

<script>
import FileAPI from '@/api/fileAPI'
import LoadingVue from '@/components/ui/Loading.vue'
import { getLab, updateLab, insertLab } from '@/api/admin/labs.js'
import { getScientificCategory } from '@/api/category/scientificCategory.js'
import { getIndustryCategory } from '@/api/category/industryCategory.js'
import { getSixTechnology, getSixTechnologyByLarge, getSixTechnologyByMeduim } from '@/api/category/sixTechnologyCategory.js'

export default {
  name: 'LabView',
  components: { LoadingVue },
  data() {
    return {
      imageAPI: new FileAPI('images'),
      fileAPIData: {},
      isLoading: false,
      isInsert: false,
      labNo: '',
      managerUserId: '',
      labName: '',
      labNameEn: '',
      leadResearcherName: '',
      leadResearcherNameEn: '',
      departmentName: '',
      affiliatedInstitutionName: '',
      labScientificCategoryId: '',
      labIndustryCategoryId: '',
      labSixTechnologyId: '',
      labSixTechnologyLarge: '',
      labSixTechnologyMeduim: '',
      labSixTechnologySmall: '',
      introduction: '',
      introductionEn: '',
      logoImgUrl: '',
      logoImgAdded: false,
      logoImgFileName: '',
      leadResearcherImgUrl: '',
      leadResearcherImgAdded: false,
      leadResearcherFileName: '',
      cintroductionEn: '',
      zipCode: '',
      email: '',
      labHomeUrl: '',
      addressMain: '',
      addressDetail: '',
      addressMainEn: '',
      addressDetailEn: '',
      phoneNumber: '',
      faxNumber: '',
      keyword: '',
      keywordEn: '',
      detailResearchField: '',
      detailResearchFieldEn: '',
      organizationExist: '',
      organizationDescription: '',
      organizationDescriptionEn: '',
      organizationTechHandover: '',
      organizationTechHandoverEn: '',
      organizationCooperation: '',
      organizationCooperationEn: '',
      labScientificCategory: [],
      labIndustryCategory: [],
      labSixTechnologyCategory: [],
      labSixTechnologyLargeList: [
        {
          sixTechnologyLarge: 'IT',
          sixTechnologyCode: '01'
        },
        {
          sixTechnologyLarge: 'BT',
          sixTechnologyCode: '02'
        },
        {
          sixTechnologyLarge: 'NT',
          sixTechnologyCode: '03'
        },
        {
          sixTechnologyLarge: 'ST',
          sixTechnologyCode: '04'
        },
        {
          sixTechnologyLarge: 'ET',
          sixTechnologyCode: '05'
        },
        {
          sixTechnologyLarge: 'CT',
          sixTechnologyCode: '06'
        },
        {
          sixTechnologyLarge: '기타',
          sixTechnologyCode: '07'
        }
      ],
      labSixTechnologyMeduimList: [],
      labSixTechnologySmallList: [],
      leadResearcherPosition: '',
      isAdmin: this.$store.getters['userStore/info'].groupName === 'admin'
    }
  },
  mounted() {
    console.log(this.isAdmin)
    this.fileAPIData = { boardName: 'lab', boardId: this.$route.params.id }

    const getData = async () => {
      this.isLoading = true

      const data = await getLab(this.$route.params.id)

      if (data.code === 'DB_DATA_NOT_FOUND') {
        alert('등록되지 않은 연구실 ID입니다.')
        return this.$router.replace('/')
      }

      const sciList = await getScientificCategory()
      const indList = await getIndustryCategory()
      const sixList = await getSixTechnology()
      const sixMeduimList = await getSixTechnologyByLarge(data.sixTechnologyLarge)
      const sixSmallList = await getSixTechnologyByMeduim(data.sixTechnologyMedium)

      const sixLargeListNew =
        sixList instanceof Array
          ? sixList.reduce(function (acc, current) {
              if (acc.findIndex(({ sixTechnologyLarge }) => sixTechnologyLarge === current.sixTechnologyLarge) === -1) {
                acc.push(current)
              }
              return acc
            }, [])
          : []

      const sixMeduimListNew =
        sixMeduimList instanceof Array
          ? sixMeduimList?.reduce(function (acc, current) {
              if (acc.findIndex(({ sixTechnologyMedium }) => sixTechnologyMedium === current.sixTechnologyMedium) === -1) {
                acc.push(current)
              }
              return acc
            }, [])
          : []

      this.labScientificCategory = sciList
      this.labIndustryCategory = indList
      this.labSixTechnologyCategory = sixList

      this.labSixTechnologyLarge = data.sixTechnologyLarge
      this.labSixTechnologyLargeList = sixLargeListNew

      this.labSixTechnologyMeduim = data.sixTechnologyMedium
      this.labSixTechnologyMeduimList = sixMeduimListNew

      this.labSixTechnologySmall = data.sixTechnologySmall
      this.labSixTechnologyId = data.sixTechnologyId
      this.labSixTechnologySmallList = sixSmallList

      this.labNo = data.labNo
      this.managerUserId = data.managerUserId
      this.labName = data.labName
      this.labNameEn = data.labNameEn
      this.leadResearcherName = data.leadResearcherName
      this.leadResearcherNameEn = data.leadResearcherNameEn
      this.leadResearcherPosition = data.leadResearcherPosition
      this.departmentName = data.departmentName
      this.affiliatedInstitutionName = data.affiliatedInstitutionName
      this.labScientificCategoryId = data.labScientificCategoryId
      this.labIndustryCategoryId = data.labIndustryCategoryId
      this.introduction = data.introduction
      this.introductionEn = data.introductionEn
      this.zipCode = data.zipCode
      this.email = data.email
      this.labHomeUrl = data.labHomeUrl
      this.addressMain = data.addressMain
      this.addressDetail = data.addressDetail
      this.addressMainEn = data.addressMainEn
      this.addressDetailEn = data.addressDetailEn
      this.phoneNumber = data.phoneNumber
      this.faxNumber = data.faxNumber
      this.keyword = data.keyword
      this.keywordEn = data.keywordEn
      this.detailResearchField = data.detailResearchField
      this.detailResearchFieldEn = data.detailResearchFieldEn
      this.organizationExist = data.organizationExist
      this.organizationDescription = data.organizationDescription
      this.organizationDescriptionEn = data.organizationDescriptionEn
      this.organizationTechHandover = data.organizationTechHandover
      this.organizationTechHandoverEn = data.organizationTechHandoverEn
      this.organizationCooperation = data.organizationCooperation
      this.organizationCooperationEn = data.organizationCooperationEn

      if (data.logoImgUrl) {
        const logoImgFileName = data.logoImgUrl.slice(data.logoImgUrl.lastIndexOf('/') + 1, data.logoImgUrl.length)
        this.$refs.logoImgPreview.src = await this.imageAPI.createObjectURL(
          logoImgFileName,
          this.fileAPIData.boardName,
          this.fileAPIData.boardId
        )
        this.logoImgFileName = logoImgFileName
      }

      if (data.leadResearcherImgUrl) {
        const leadResearcherImgFileName = data.leadResearcherImgUrl.slice(
          data.leadResearcherImgUrl.lastIndexOf('/') + 1,
          data.leadResearcherImgUrl.length
        )
        this.$refs.leadResearcherImgPreview.src = await this.imageAPI.createObjectURL(
          leadResearcherImgFileName,
          this.fileAPIData.boardName,
          this.fileAPIData.boardId
        )
        this.leadResearcherImgFileName = leadResearcherImgFileName
      }

      this.isLoading = false
    }

    const getCategory = async () => {
      const sciList = await getScientificCategory()
      const indList = await getIndustryCategory()
      const sixList = await getSixTechnology()

      this.labScientificCategory = sciList
      this.labIndustryCategory = indList
      this.labSixTechnologyCategory = sixList
    }

    if (this.$route.params.id) {
      getData()
    } else {
      this.isInsert = true
      getCategory()
    }
  },
  methods: {
    uploadFileHandler(e) {
      const target = e.target
      const name = target.name
      const file = target.files[0]

      const preview = this.$refs[`${name}Preview`]

      preview.src = URL.createObjectURL(file)
    },
    async uploadImage() {
      await this.imageAPI.deleteAll(this.fileAPIData)

      const leadResearcherImg = this.$refs.form.leadResearcherImg.files[0] ?? this.imageAPI._blobMap[this.leadResearcherImgFileName]
      const logoImg = this.$refs.form.logoImg.files[0] ?? this.imageAPI._blobMap[this.logoImgFileName]

      if (leadResearcherImg) {
        this.logoImgAdded = true
        await this.imageAPI.add(leadResearcherImg)
      }

      if (logoImg) {
        this.imageAPIAdded = true
        await this.imageAPI.add(logoImg)
      }

      const result = await this.imageAPI.send(this.fileAPIData, { fullUrl: true })
      return result
    },
    async saveData() {
      this.isLoading = true
      const [leadResearcherImgUrl, logoImgUrl] = await this.uploadImage()

      if (!this.logoImgAdded && this.imageAPIAdded) {
        this.logoImgUrl = leadResearcherImgUrl
      } else {
        this.leadResearcherImgUrl = leadResearcherImgUrl
        this.logoImgUrl = logoImgUrl
      }

      if (this.$route.params.id) {
        this.updateLabData()
      } else {
        this.insertLabData()
      }
      this.isLoading = false
    },
    async updateLabData() {
      const data = {
        labNo: this.labNo,
        managerUserId: this.managerUserId,
        labName: this.labName,
        labNameEn: this.labNameEn,
        leadResearcherName: this.leadResearcherName,
        leadResearcherNameEn: this.leadResearcherNameEn,
        leadResearcherPosition: this.leadResearcherPosition,
        departmentName: this.departmentName,
        affiliatedInstitutionName: this.affiliatedInstitutionName,
        labScientificCategoryId: this.labScientificCategoryId,
        labIndustryCategoryId: this.labIndustryCategoryId,
        sixTechnologyId: this.labSixTechnologyId,
        introduction: this.introduction,
        introductionEn: this.introductionEn,
        logoImgUrl: this.logoImgUrl,
        leadResearcherImgUrl: this.leadResearcherImgUrl,
        zipCode: this.zipCode,
        email: this.email,
        labHomeUrl: this.labHomeUrl,
        addressMain: this.addressMain,
        addressDetail: this.addressDetail,
        addressMainEn: this.addressMainEn,
        addressDetailEn: this.addressDetailEn,
        phoneNumber: this.phoneNumber,
        faxNumber: this.faxNumber,
        keyword: this.keyword,
        keywordEn: this.keywordEn,
        detailResearchField: this.detailResearchField,
        detailResearchFieldEn: this.detailResearchFieldEn,
        organizationExist: this.organizationExist,
        organizationDescription: this.organizationDescription,
        organizationDescriptionEn: this.organizationDescriptionEn,
        organizationTechHandover: this.organizationTechHandover,
        organizationTechHandoverEn: this.organizationTechHandoverEn,
        organizationCooperation: this.organizationCooperation,
        organizationCooperationEn: this.organizationCooperationEn
      }

      await updateLab(data.labNo, data)
    },
    async insertLabData() {
      const data = {
        // labNo: this.labNo,
        managerUserId: this.managerUserId,
        labName: this.labName,
        labNameEn: this.labNameEn,
        leadResearcherName: this.leadResearcherName,
        leadResearcherNameEn: this.leadResearcherNameEn,
        leadResearcherPosition: this.leadResearcherPosition,
        departmentName: this.departmentName,
        affiliatedInstitutionName: this.affiliatedInstitutionName,
        labScientificCategoryId: this.labScientificCategoryId,
        labIndustryCategoryId: this.labIndustryCategoryId,
        sixTechnologyId: this.labSixTechnologyId,
        introduction: this.introduction,
        introductionEn: this.introductionEn,
        logoImgUrl: this.logoImgUrl,
        leadResearcherImgUrl: this.leadResearcherImgUrl,
        zipCode: this.zipCode,
        email: this.email,
        labHomeUrl: this.labHomeUrl,
        addressMain: this.addressMain,
        addressDetail: this.addressDetail,
        addressMainEn: this.addressMainEn,
        addressDetailEn: this.addressDetailEn,
        phoneNumber: this.phoneNumber,
        faxNumber: this.faxNumber,
        keyword: this.keyword,
        keywordEn: this.keywordEn,
        detailResearchField: this.detailResearchField,
        detailResearchFieldEn: this.detailResearchFieldEn,
        organizationExist: this.organizationExist,
        organizationDescription: this.organizationDescription,
        organizationDescriptionEn: this.organizationDescriptionEn,
        organizationTechHandover: this.organizationTechHandover,
        organizationTechHandoverEn: this.organizationTechHandoverEn,
        organizationCooperation: this.organizationCooperation,
        organizationCooperationEn: this.organizationCooperationEn
      }

      await insertLab(data)
    },
    textLimitCheck(where, count) {
      // TODO: 글자수 재한을 위한 코드
      const target = document.getElementsByClassName(where, count)
      target[0].value = target[0].value.substring(0, count)
    },
    categoryChange(e) {
      const targetId = e.srcElement.id
      if (targetId === 'labScientificCategoryId') {
        this.labScientificCategoryId = Number(document.getElementById(targetId).value)
      }
      if (targetId === 'labIndustryCategoryId') {
        this.labIndustryCategoryId = Number(document.getElementById(targetId).value)
      }
      if (targetId === 'labsixTechnologySmall') {
        this.labSixTechnologyId = Number(document.getElementById(targetId).value)
      }
    },
    async SixCategoryChangeLarge(e) {
      const targetId = e.srcElement.id
      const sixLarge = document.getElementById(targetId).value
      const result = await getSixTechnologyByLarge(sixLarge)

      const newResult = result.reduce(function (acc, current) {
        if (acc.findIndex(({ sixTechnologyMedium }) => sixTechnologyMedium === current.sixTechnologyMedium) === -1) {
          acc.push(current)
        }
        return acc
      }, [])
      this.labSixTechnologyLarge = sixLarge
      this.labSixTechnologyMeduimList = newResult
    },
    async SixCategoryChangeMedium(e) {
      const targetId = e.srcElement.id
      const sixMeduim = document.getElementById(targetId).value
      const result = await getSixTechnologyByMeduim(sixMeduim)
      const newResult = result.reduce(function (acc, current) {
        if (acc.findIndex(({ sixTechnologySmall }) => sixTechnologySmall === current.sixTechnologySmall) === -1) {
          acc.push(current)
        }
        return acc
      }, [])
      this.labSixTechnologySmall = sixMeduim
      this.labSixTechnologySmallList = newResult
    },

    goMails() {
      this.$router.push(`/admin/mails/${this.labNo}`)
    }
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading" />

  <div class="adminTable">
    <div class="wf__btn-area">
      <button type="button" class="e-btn wf__btn btn-primary" @click="goMails">기업협력 신청 목록</button>
    </div>
    <form action="#" method="post" id="basicform" @submit.prevent="saveData" ref="form">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="20%" />
          <col width="20%" />
          <col width="20%" />
        </colgroup>
        <thead>
          <th>구분</th>
          <th>한글</th>
          <th>영문</th>
        </thead>
        <tbody>
          <tr v-bind:class="{ displayNone: this.isInsert == true }">
            <th>연구실번호</th>
            <td><input type="text" name="labNo" v-model="labNo" :disabled="this.isInsert === false" /></td>
            <td></td>
          </tr>
          <tr>
            <th>관리자 ID</th>
            <!-- 최고관리자일경우 변경 가능하게 -->
            <td>
              <input type="text" name="managerUserId" v-model="managerUserId" :disabled="!(this.isAdmin || this.isInsert === false)" />
            </td>
            <td></td>
          </tr>
          <tr>
            <th>연구실이름</th>
            <td><input type="text" name="labName" v-model="labName" /></td>
            <td><input type="text" name="labNameEn" v-model="labNameEn" /></td>
          </tr>
          <tr>
            <th>대표 연구자</th>
            <td><input type="text" name="leadResearcherName" v-model="leadResearcherName" /></td>
            <td><input type="text" name="leadResearcherNameEn" v-model="leadResearcherNameEn" /></td>
          </tr>
          <tr>
            <th>대표 연구자 직위</th>
            <td><input type="text" name="leadResearcherPosition" v-model="leadResearcherPosition" /></td>
          </tr>
          <tr>
            <th>학과(부서)</th>
            <td><input type="text" name="departmentName" v-model="departmentName" /></td>
            <td></td>
          </tr>
          <tr>
            <th>학교(소속기관)</th>
            <td><input type="text" name="affiliatedInstitutionName" v-model="affiliatedInstitutionName" /></td>
            <td></td>
          </tr>
          <tr>
            <th>이메일</th>
            <td><input type="text" name="email" v-model="email" /></td>
            <td></td>
          </tr>
          <tr>
            <th>개별홈페이지주소</th>
            <td><input type="text" name="labHomeUrl" v-model="labHomeUrl" /></td>
            <td></td>
          </tr>
          <tr>
            <th>과학기술분류</th>
            <td>
              <select @change="categoryChange" id="labScientificCategoryId" v-model="this.labScientificCategoryId">
                <option v-bind:key="i" v-for="(item, i) in labScientificCategory" :value="item.labScientificCategoryId">
                  {{ item.labScientificCategoryName }}
                </option>
              </select>
            </td>
            <td></td>
          </tr>
          <tr>
            <th>산업분류</th>
            <td>
              <select @change="categoryChange" id="labIndustryCategoryId" v-model="this.labIndustryCategoryId">
                <option v-bind:key="i" v-for="(item, i) in labIndustryCategory" :value="item.labIndustryCategoryId">
                  {{ item.labIndustryCategoryName }}
                </option>
              </select>
            </td>
            <td></td>
          </tr>
          <tr>
            <th>6T분류</th>
            <td>
              <select @change="SixCategoryChangeLarge" id="labsixTechnologyLarge" v-model="this.labSixTechnologyLarge">
                <option v-bind:key="i" v-for="(item, i) in labSixTechnologyLargeList" :value="item.sixTechnologyLarge">
                  {{ item.sixTechnologyLarge }}
                </option>
              </select>
              <select @change="SixCategoryChangeMedium" id="labSixTechnologyMeduim" v-model="this.labSixTechnologyMeduim">
                <option v-bind:key="i" v-for="(item, i) in labSixTechnologyMeduimList" :value="item.sixTechnologyMedium">
                  {{ item.sixTechnologyMedium }}
                </option>
              </select>
              <select @change="categoryChange" id="labsixTechnologySmall" v-model="this.labSixTechnologyId">
                <option v-bind:key="i" v-for="(item, i) in labSixTechnologySmallList" :value="item.sixTechnologyId">
                  {{ item.sixTechnologySmall }}
                </option>
              </select>
            </td>
            <td></td>
          </tr>
          <tr>
            <th>세부 연구분야</th>
            <td>
              <textarea
                type="text"
                name="detailResearchField"
                class="tbl_addr detailResearchField"
                v-model="detailResearchField"
                cols="40"
                rows="7"
              ></textarea>
            </td>
            <td>
              <textarea
                type="text"
                name="detailResearchFieldEn"
                class="tbl_addr detailResearchFieldEn"
                v-model="detailResearchFieldEn"
                cols="40"
                rows="7"
              ></textarea>
            </td>
          </tr>
          <tr>
            <th>짧은소개</th>
            <td>
              <textarea type="text" name="introduction" class="tbl_addr introKr" v-model="introduction" cols="40" rows="7"></textarea>
            </td>
            <td>
              <textarea type="text" name="introductionEn" class="tbl_addr introEn" v-model="introductionEn" cols="40" rows="7"></textarea>
            </td>
          </tr>
          <tr>
            <th>책임연구자 사진</th>
            <td><input type="file" name="leadResearcherImg" @change="uploadFileHandler" /></td>
            <td><img class="imgUrl" :src="this.leadResearcherImgUrl" ref="leadResearcherImgPreview" /></td>
          </tr>
          <tr>
            <th>연구실 로고</th>
            <td><input type="file" name="logoImg" @change="uploadFileHandler" /></td>
            <td><img class="imgUrl" :src="this.logoImgUrl" ref="logoImgPreview" /></td>
          </tr>
          <tr>
            <th>주소</th>
            <td>
              <div class="daummap">
                <input type="text" name="zipCode" v-model="zipCode" placeholder="우편번호" /><br />
                <input class="tbl_addr" type="text" name="addressMain" v-model="addressMain" placeholder="기본주소" /><br />
                <input class="tbl_addr" type="text" name="addressDetail" v-model="addressDetail" placeholder="상세 주소" />
                <input class="e-btn wf__btn btn-primary" type="button" @click="this.$daumAddressAPI()" value="주소API 호출" />
              </div>
            </td>
            <td>
              <input class="tbl_addr" type="text" name="addressMainEn" v-model="addressMainEn" placeholder="기본주소" /><br />
              <input class="tbl_addr" type="text" name="addressDetailEn" v-model="addressDetailEn" placeholder="상세 주소" />
            </td>
          </tr>

          <tr>
            <th>Keyword</th>
            <td>
              <textarea
                type="text"
                name="keyword"
                class="tbl_addr keyword"
                v-model="keyword"
                cols="40"
                rows="7"
                @keyup="textLimitCheck('keyword', 128)"
              ></textarea>
            </td>
            <td>
              <textarea
                type="text"
                name="keywordEn"
                class="tbl_addr keywordEn"
                v-model="keywordEn"
                cols="40"
                rows="7"
                @keyup="textLimitCheck('keywordEn', 128)"
              ></textarea>
            </td>
          </tr>
          <tr>
            <th>전담조직 유무</th>
            <td>
              <input type="radio" name="organizationExist" v-model="this.organizationExist" value="1" />
              <span>있음</span>
              <input type="radio" name="organizationExist" v-model="this.organizationExist" value="0" />
              <span>없음</span>
            </td>
          </tr>
          <tr>
            <th>전담조직<br />세부사항</th>
            <td>
              <textarea
                type="text"
                name="organizationDescription"
                class="tbl_addr organizationDescription"
                v-model="organizationDescription"
                cols="40"
                rows="7"
              ></textarea>
            </td>
            <td>
              <textarea
                type="text"
                name="organizationDescriptionEn"
                class="tbl_addr organizationDescriptionEn"
                v-model="organizationDescriptionEn"
                cols="40"
                rows="7"
              ></textarea>
            </td>
          </tr>
          <tr>
            <th>전담조직<br />기업협력 경험</th>
            <td>
              <textarea
                type="text"
                name="organizationTechHandover"
                class="tbl_addr organizationTechHandover"
                v-model="organizationTechHandover"
                cols="40"
                rows="7"
              ></textarea>
            </td>
            <td>
              <textarea
                type="text"
                name="organizationTechHandoverEn"
                class="tbl_addr organizationTechHandoverEn"
                v-model="organizationTechHandoverEn"
                cols="40"
                rows="7"
              ></textarea>
            </td>
          </tr>
          <tr>
            <th>협력파트너쉽 현황</th>
            <td>
              <textarea
                name="organizationCooperation"
                type="text"
                class="tbl_addr organizationCooperation"
                v-model="organizationCooperation"
                cols="40"
                rows="7"
              ></textarea>
            </td>
            <td>
              <textarea
                type="text"
                name="organizationCooperationEn"
                class="tbl_addr organizationCooperationEn"
                v-model="organizationCooperationEn"
                cols="40"
                rows="7"
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>
      <button class="e-btn wf__btn btn-success mt-2" type="submit" id="save">저장하기</button>
      <!-- <button type="button" @click="uploadImage">test</button> -->
    </form>
  </div>
</template>

<style scoped>
.adminTable input {
  margin-bottom: 0.25em;
}
.tbl_addr {
  width: 300px;
}
.displayNone {
  display: none;
}
.imgUrl {
  width: 200px;
  height: 200px;
  object-fit: scale-down;
  object-position: center;
}

.wf__btn-area {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin: 1rem;
  margin-left: 0;
}

#labsixTechnologySmall {
  max-width: 20em;
}
</style>
