import { useAxios } from '../axiosUtil'
import router from '@/router'

const token = localStorage.getItem('token')
const tokenType = localStorage.getItem('tokenType')
const headerPut = {
  method: 'put',
  headers: {
    Authorization: `${tokenType} ${token}`
  }
}
const headerPost = {
  method: 'post',
  headers: {
    Authorization: `${tokenType} ${token}`
  }
}
const root = '/v1/lab'

export const getLabList = async () => {
  const result = await useAxios(`${root}`)
  if (result) return result

  if (!result) {
    alert('정보가 없습니다.')
    router.go(-1)
    return
  }

  if (result.message || result.code === 9999) {
    alert('오류가 발생했습니다.')
    router.go(-1)
  }
}

export const getLab = async (labNo) => {
  const result = await useAxios(`${root}/${labNo}`)
  if (result) return result

  if (!result) {
    alert('정보가 없습니다.')
    router.go(-1)
    return
  }

  if (result.message || result.code === 9999) {
    alert('오류가 발생했습니다.')
    router.go(-1)
  }
}

export const updateLab = async (labNo, data) => {
  const labDataOpt = { data: data, ...headerPut }
  const url = `${root}/${labNo}`
  const result = useAxios(url, labDataOpt)
    .then((res) => {
      if (res) {
        alert('저장 되었습니다.')
        router.go()
      }
    })
    .catch((error) => {
      console.log(error)
    })

  if (result.message || result.code === 9999) {
    alert('오류가 발생했습니다.')
  }
}

export const insertLab = async (data) => {
  const labDataOpt = { data: data, ...headerPost }
  // console.log(data)
  const url = `${root}/`
  const result = useAxios(url, labDataOpt)
    .then((res) => {
      // console.log(res)
      if (res) {
        alert('저장 되었습니다.')
        router.go()
      }
    })
    .catch((error) => {
      console.log(error)
    })

  if (result.message || result.code === 9999) {
    alert('오류가 발생했습니다.')
    // router.go(-1)
  }
}
